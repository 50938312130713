import { queryClient } from "#/lib/query";
/* eslint-disable import/no-anonymous-default-export */
import { analytics, events } from "#/lib/analytics";
import { atoms } from "#/lib/atoms/atoms";
import cookies from "#/lib/cookies";
import {
  FetchError,
  deleteRequest,
  fetchRequest,
  pollRequest,
  postRequest,
  putRequest,
} from "#/lib/stream-api";
import { toast } from "#/lib/toast";
import { DeleteMenuDto } from "#/types.ts/Api";
import { SchedulesByMenuId } from "#/types.ts/schedules.types";
import { components, operations } from "#/types.ts/swagger";
import { MENU_MATCH_STATUS } from "#/utils/MenuMatchUtils";
import { isRequireMenuRefresh, updateConstruct } from "#/utils/MenuUtils";
import { DSP } from "#/utils/platform.utils";
import axios from "axios";
import { atomWithMutation } from "jotai-tanstack-query";
import { IFixMe } from "../../types.ts/other";

const wrapper = <TVariables = undefined, TData = unknown, TError = Error>(
  getOptions: (p: any) => {
    mutationKey: string[];
    mutationFn: (variables: TVariables) => Promise<TData>;
    onError?: (error: TError) => void;
    onSuccess?: (data: TData, variables?: TVariables, context?: any) => void;
  },
) => {
  return atomWithMutation(
    (p) => {
      const o = getOptions(p);
      if (!o.onError) {
        o.onError = (error: TError) => toast.error((error as Error)?.message);
      }
      return o;
    },
    () => queryClient,
  );
};

const invalidateCatalog = (context?: any, updateData?: any) => {
  /** Refetch details no matter what */
  queryClient.invalidateQueries({
    queryKey: ["catalog", "details"],
    type: "inactive",
  });
  queryClient.invalidateQueries({
    queryKey: ["catalog", "head"],
    type: "inactive",
  });
  queryClient.refetchQueries({
    queryKey: ["catalog", "details"],
    type: "active",
  });

  if (
    (updateData && isRequireMenuRefresh(updateData)) ||
    context?.needsForcePublish
  ) {
    queryClient.refetchQueries({
      queryKey: ["catalog", "head"],
      type: "active",
    });
  }
};

const getCachedCatalog = async (get) => {
  const head = queryClient.getQueryData([
    "catalog",
    "head",
    get(atoms.currentLocation)?._id,
  ]) as any;

  await queryClient.cancelQueries({
    queryKey: ["catalog", get(atoms.currentLocation)?._id],
  });

  return queryClient.getQueryData([
    "catalog",
    get(atoms.currentLocation)?._id,
    head,
  ]) as any;
};

export const mutations = {
  saveLocationScheduleExceptions: wrapper<
    components["schemas"]["SaveLocationScheduleExceptionsDto"]["schedule_exceptions"]
  >((get) => ({
    mutationKey: ["saveLocationScheduleExceptions"],
    onSuccess: () => {
      toast.success("Holiday exception saved");
      queryClient.refetchQueries({
        queryKey: ["menuSchedules", get(atoms.currentLocation)?._id],
        type: "active",
      });
    },
    mutationFn: async (schedule_exceptions): Promise<string> => {
      return await postRequest(`/location/schedule-exceptions`, {
        arg: {
          schedule_exceptions,
          location_id:
            get(atoms.locationToAddDspTo) || get(atoms.currentLocation)._id,
        },
      });
    },
  })),
  updateIntegration: wrapper<
    components["schemas"]["UpdateIntegrationMetadataDto"]
  >((get) => ({
    mutationKey: ["updateIntegration"],
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ["integrations"],
        type: "active",
      });
    },
    mutationFn: async (body): Promise<string> => {
      return await postRequest(`/integrations/metadata`, {
        arg: body,
      });
    },
  })),
  authLogin: wrapper(() => ({
    mutationKey: ["authLogin"],
    mutationFn: async (
      payload,
    ): Promise<{
      access_token: string;
      refresh_token: string;
    }> => {
      return postRequest(`/auth/login`, {
        arg: payload,
      });
    },
  })),
  userUpdate: wrapper<any>(() => ({
    mutationKey: ["onboard"],
    mutationFn: async (payload): Promise<string> => {
      return postRequest(`/user`, {
        arg: payload,
      });
    },
  })),
  authExisting: wrapper(() => ({
    mutationKey: ["authExisting"],
    mutationFn: async (payload) => {
      return await postRequest(`/auth/email/existing`, {
        arg: payload,
      });
    },
  })),

  authRegister: wrapper(() => ({
    mutationKey: ["authRegister"],
    mutationFn: async (payload) => {
      return await postRequest(`/auth/register`, {
        arg: payload,
      });
    },
  })),
  duplicatePosLocation: wrapper<
    components["schemas"]["DuplicatePosLocationDto"]
  >(() => ({
    mutationKey: ["duplicatePosLocation"],
    onSuccess: () => {
      toast.success("Location duplicated");
      queryClient.refetchQueries({
        queryKey: ["locations"],
        type: "active",
      });
      queryClient.refetchQueries({
        queryKey: ["merchant", "locations"],
        type: "active",
      });
      queryClient.refetchQueries({
        queryKey: ["orgs"],
        type: "active",
      });
    },
    mutationFn: async (
      payload: components["schemas"]["DuplicatePosLocationDto"],
    ): Promise<string> => {
      return await postRequest(`/location/duplicate`, {
        arg: payload,
      });
    },
  })),
  removeNotificationConfig: wrapper<{
    id: string;
    locationId: string;
  }>(() => ({
    mutationKey: ["deleteNotificationConfig"],
    onSuccess: (locationId) => {
      toast.success("Success");
      queryClient.invalidateQueries({
        queryKey: ["locationEventProviders", locationId],
        type: "active",
      });
    },
    mutationFn: async ({ id, locationId }) => {
      await deleteRequest(`/location-event/config/${id}`, {
        arg: {
          location_id: locationId,
        },
      });
      return locationId;
    },
  })),
  createLocationEventConfig: wrapper<
    components["schemas"]["CreateLocationEventConfigDto"]
  >(() => ({
    mutationKey: ["createLocationEventConfig"],
    onSuccess: (id) => {
      queryClient.refetchQueries({
        queryKey: ["locationEventProviders", id],
        type: "active",
      });
    },
    mutationFn: async (arg): Promise<string> => {
      await postRequest(`/location-event/config`, { arg });
      return arg.location_id;
    },
  })),
  updateLocationEventConfig: wrapper<
    components["schemas"]["UpdateLocationEventConfigDto"] & {
      location_id: string;
    }
  >(() => ({
    mutationKey: ["updateLocationEventConfig"],
    onSuccess: (id) => {
      queryClient.refetchQueries({
        queryKey: ["locationEventProviders", id],
        type: "active",
      });
    },
    mutationFn: async ({ location_id, ...arg }): Promise<string> => {
      await postRequest(`/location-event/config/update`, { arg });
      return location_id;
    },
  })),
  toggleOrdersAutoConfirm: wrapper<IFixMe>((get) => ({
    mutationKey: ["toggleOrdersAutoConfirm"],
    onSuccess: () => {
      toast.success("Success");
    },
    mutationFn: async (schedule_exceptions): Promise<string> => {
      return await postRequest(`/merchant/order`, {
        arg: {
          schedule_exceptions,
          location_id:
            get(atoms.locationToAddDspTo) || get(atoms.currentLocation)._id,
        },
      });
    },
  })),
  uploadFile: wrapper<
    {
      file: File;
      type: "menu" | "item" | "merchant";
    },
    string
  >((get) => ({
    mutationKey: ["uploadFile"],
    onError: () => toast.error("There was an error uploading this photo"),
    mutationFn: async ({ file, type }): Promise<string> => {
      const fileName = file.name;
      const formData = new FormData();
      const name = fileName?.split(".").pop();
      formData.append("file", file);
      if (type === "menu") {
        formData.append("menu_id", get(atoms.currentMenu)?._id);
      }
      formData.append("file_name", `${crypto.randomUUID()}.${name}`);

      const partner_id = cookies.get(cookies.options.partner_id);
      const { data } = await axios.post(
        `${process.env.NEXT_PUBLIC_BASE_URL}/image/upload`,
        formData,
        {
          headers: {
            access_token: cookies.get(cookies.options.access_token),
            merchant_id: cookies.get(cookies.options.merchant_id),
            ...(partner_id && { "x-partner-id": partner_id }),
          },
        },
      );
      return data.url;
    },
  })),
  dspActivate: wrapper<components["schemas"]["RequestDspIntegrationDto"]>(
    (get) => ({
      mutationKey: ["dspActivation"],
      onSuccess: (source) => {
        toast.success("DSP activated");
        queryClient.refetchQueries({
          queryKey: ["dspLocations"],
          type: "active",
        });
        queryClient.refetchQueries({
          queryKey: ["integrations"],
          type: "active",
        });
        queryClient.refetchQueries({
          queryKey: [
            "connectedDspLocations",
            get(atoms.locationToAddDspTo) || get(atoms.currentLocation)._id,
          ],
          type: "active",
        });
        analytics.track(events.DSP_CONNECTED, {
          source,
        });
      },
      mutationFn: async (data): Promise<string> => {
        await postRequest(`/integrations/dsp-activation-request`, {
          arg: {
            ...data,
            location_id:
              data?.location_id ||
              get(atoms.locationToAddDspTo) ||
              get(atoms.currentLocation)._id,
          },
        });
        return data.source;
      },
    }),
  ),
  integrationCreateAccount: wrapper<string>((get) => ({
    mutationKey: ["dspAccountCreate"],
    onSuccess: (source) => {
      toast.success("DSP account created");

      window.open(source as URL, "_self");
      // navigate them  here
      // analytics.track(events.DSP_CONNECTED, {
      //   source,
      // });
    },
    mutationFn: async (source) => {
      const data: components["schemas"]["InitializeAccountCreationResponseDto"] =
        await postRequest(`/dsp/integration/${source}/create-account`, {
          arg: {
            locale: navigator.language,
            location_id:
              get(atoms.locationToAddDspTo) || get(atoms.currentLocation)._id,
          },
        });
      return data.signup_url;
    },
  })),
  submitMenu: wrapper<
    components["schemas"]["PublishLocationDto"],
    components["schemas"]["PublishLocationResponseDto"] & {
      locationId: string;
      source: string;
    }
  >((get) => ({
    mutationKey: ["submitMenu"],
    onSuccess: (data) => {
      const { source, locationId } = data;
      queryClient.refetchQueries({
        queryKey: ["dspsPublishStatus", locationId],
      });
      queryClient.refetchQueries({
        queryKey: ["menus", get(atoms.currentLocation)?._id],
        exact: true,
        type: "active",
      });
      analytics.track(events.DSP_PUBLISH, { source });
    },
    mutationFn: async (data) => {
      const response = await postRequest(`/dsp/publish`, {
        arg: {
          ...data,
          location_id: get(atoms.currentLocation)._id,
        },
      });
      return {
        ...response,
        locationId: get(atoms.currentLocation)._id,
        source: data.source,
      };
    },
  })),
  createCategory: wrapper<
    components["schemas"]["CreateMenuCategoryDto"],
    components["schemas"]["CreateMenuCategoryResponseDto"]
  >(() => ({
    mutationKey: ["createCategory"],
    onSuccess: () => {
      toast.success(`Success`);
      invalidateCatalog(undefined, { force: true });
    },
    onError: () => toast.error("Failed to create category"),
    mutationFn: (params) => {
      return postRequest(`/menu/create_category`, {
        arg: params,
      });
    },
  })),
  addCategories: wrapper<
    components["schemas"]["AddMenuCategoriesDto"],
    components["schemas"]["AddMenuCategoriesResponseDto"]
  >(() => ({
    mutationKey: ["addCategories"],
    onSuccess: () => {
      toast.success(`Success`);
      invalidateCatalog(undefined, { force: true });
    },
    onError: () => toast.error("Failed to add categories to menu"),
    mutationFn: (params) => {
      return postRequest(`/menu/add_categories`, {
        arg: params,
      });
    },
  })),
  dspSmartMatchLocation: wrapper<
    components["schemas"]["SmartMatchLocationsDto"],
    components["schemas"]["SmartMatchLocationsResponseDto"]
  >(() => ({
    mutationKey: ["dspSmartMatchLocation"],
    onError: () => toast.error("Failed to match your locations"),
    mutationFn: (params) => {
      return postRequest(`/dsp/smart-match-locations`, {
        arg: params,
      });
    },
  })),
  removeCategory: wrapper<
    components["schemas"]["RemoveMenuCategoryDto"],
    components["schemas"]["RemoveMenuCategoryResponseDto"]
  >(() => ({
    mutationKey: ["removeCategory"],
    onSuccess: () => {
      toast.success(`Success`);
      invalidateCatalog(undefined, { force: true });
    },
    onError: () => toast.error("Failed to remove category from menu"),
    mutationFn: (params) => {
      return postRequest(`/menu/remove_category`, {
        arg: params,
      });
    },
  })),
  createModifierGroup: wrapper<
    {
      create_data: components["schemas"]["CreateCatalogObjectsDto"]["create_data"];
      location_ids: components["schemas"]["CreateCatalogObjectsDto"]["location_ids"];
    },
    components["schemas"]["CreateCatalogObjectsResponseDto"]
  >((get) => ({
    mutationKey: ["createModifierGroup"],
    onSuccess: async (data) => {
      toast.success(`Success`);

      const newModifierGroup = data.objects?.["modifier_groups"]?.[0];
      if (!newModifierGroup) {
        toast.error("Please refresh to see your new group");
        return;
      }
      const oldValue = await getCachedCatalog(get);
      const { catalog } = oldValue as any;
      catalog.modifier_group.push(newModifierGroup);
      queryClient.setQueriesData(
        {
          queryKey: ["catalog", get(atoms.currentLocation)?._id],
        },
        oldValue,
      );
    },
    onError: () => toast.error("Failed to create modifier group"),
    mutationFn: (data) => {
      return postRequest(`/catalog/create_catalog_objects`, {
        arg: {
          ...data,
          catalog_id: get(atoms.currentCatalog)?._id,
        },
      });
    },
  })),
  sortMenus: wrapper<components["schemas"]["SortMenusForLocationsDto"]>(
    (get) => ({
      mutationKey: ["sortMenus"],
      onSuccess: () => {
        toast.success(`Success`);
        queryClient.refetchQueries({
          queryKey: ["menus", get(atoms.currentLocation)?._id],
          type: "active",
        });
      },
      onError: () => toast.error("Failed to create modifier group"),
      mutationFn: (data) => {
        return putRequest(`/menu/sort`, {
          arg: data,
        });
      },
    }),
  ),
  catalogUpdate: wrapper<
    components["schemas"]["CatalogUpdateDto"]["update_data"] & {
      flag?: components["schemas"]["CatalogUpdateDto"]["flag"];
      publish?: components["schemas"]["CatalogUpdateDto"]["publish"];
    },
    components["schemas"]["CatalogUpdateResponseDto"]
  >((get) => ({
    mutationKey: [
      "catalogUpdate",
      get(atoms.currentCatalog)?._id,
      get(atoms.currentLocation)?._id,
    ],
    // @ts-ignore
    onError: (error, _, context) => {
      if (context?.oldCatalog) {
        queryClient.setQueriesData(
          {
            queryKey: [
              "catalog",
              get(atoms.currentLocation)?._id,
              context?.head,
            ],
          },
          context?.oldCatalog,
        );
      }
      if (context?.oldMenu) {
        queryClient.setQueriesData(
          {
            queryKey: [
              "menu",
              get(atoms.currentMenu)?._id,
              get(atoms.currentLocation)?._id,
              context?.head,
            ],
          },
          context?.oldMenu,
        );
      }
      toast.error(error.message || "Failed to update catalog");
    },
    onMutate: async (newData) => {
      let needsForcePublish;
      const head = queryClient.getQueryData([
        "catalog",
        "head",
        get(atoms.currentLocation)?._id,
      ]) as any;

      await queryClient.cancelQueries({
        queryKey: ["catalog", get(atoms.currentLocation)?._id],
      });

      const oldCatalog = queryClient.getQueryData([
        "catalog",
        get(atoms.currentLocation)?._id,
        head,
      ]) as any;

      if (oldCatalog?.catalog) {
        const { construct: updatedCatalog, needs_force_refresh } =
          updateConstruct(oldCatalog.catalog, newData);
        queryClient.setQueriesData(
          {
            queryKey: ["catalog", get(atoms.currentLocation)?._id, head],
          },
          {
            ...oldCatalog,
            catalog: updatedCatalog,
          },
        );
        if (needs_force_refresh) needsForcePublish = needs_force_refresh;
      }

      const oldMenu = queryClient.getQueryData([
        "menu",
        get(atoms.currentMenu)?._id,
        get(atoms.currentLocation)?._id,
        head,
      ]) as any;

      if (oldMenu?.menu) {
        const { construct: updatedMenu, needs_force_refresh } = updateConstruct(
          oldMenu.menu,
          newData,
        );
        queryClient.setQueriesData(
          {
            queryKey: [
              "menu",
              get(atoms.currentMenu)?._id,
              get(atoms.currentLocation)?._id,
              head,
            ],
          },
          {
            ...oldMenu,
            menu: updatedMenu,
          },
        );
        if (needs_force_refresh) needsForcePublish = needs_force_refresh;
      }

      await new Promise((res) => {
        setTimeout(() => {
          queryClient.refetchQueries({
            queryKey: ["active-modifier-group"],
            type: "active",
          });
          queryClient.refetchQueries({
            queryKey: ["active-modifiers"],
            type: "active",
          });
          res("");
        }, 0);
      });
      return {
        head,
        oldMenu: oldMenu,
        oldCatalog: oldCatalog,
        needsForcePublish,
      };
    },
    onSuccess: (_, updateData, context) => {
      if (!isSortableListUpdate(updateData)) {
        toast.success("Success");
      }

      invalidateCatalog(context, updateData);

      if (updateData.publish) {
        /** This needs to be delayed otherwise it wont get latest status */
        setTimeout(() => {
          queryClient.refetchQueries({
            queryKey: [
              "locationPublishStatus",
              get(atoms.currentLocation)?._id,
            ],
          });
        }, 500);
      }

      analytics.track(events.UPDATED_ITEM);

      function isSortableListUpdate(updateData) {
        if (
          updateData?.modifier_group?.length === 1 &&
          updateData?.modifier_group?.[0]?.modifier_ids
        ) {
          return true;
        }
        return false;
      }
    },
    // @ts-ignore
    mutationFn: async (data) => {
      const payloadData = {
        ...(data?.flag && { flag: data.flag }),
        ...(data?.publish && { publish: data.publish }),
        catalog_id: get(atoms.currentCatalog)?._id,
        location_id: get(atoms.currentLocation)?._id,
        update_data: {
          ...data,
        },
      };
      await postRequest(`/catalog/update`, {
        arg: payloadData,
      });
      return payloadData;
    },
  })),
  bindAndPublish: wrapper<{
    source: string;
    locationId: string;
  }>((get) => ({
    mutationKey: ["publishAndBind"],
    onSuccess: () => {
      toast.success("Published");
      queryClient.refetchQueries({
        queryKey: ["connectedDspLocations"],
        type: "active",
      });
      queryClient.refetchQueries({
        queryKey: ["locationPublishStatus", get(atoms.currentLocation)?._id],
        type: "active",
      });
    },
    onError: () => toast.error("Failed to bind dsp"),
    mutationFn: async ({ source, locationId }) => {
      return await putRequest(`/dsp/${source}/publish-and-bind`, {
        arg: {
          location_id: locationId,
        },
      });
    },
  })),

  rebindDsp: wrapper<
    components["schemas"]["RebindDspLocationDto"] & { source: string }
  >((get) => ({
    mutationKey: ["rebindDsp"],
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ["connectedDspLocations"],
        type: "active",
      });
      queryClient.refetchQueries({
        queryKey: ["dspLocations"],
        type: "active",
      });
      queryClient.refetchQueries({
        queryKey: ["locationPublishStatus", get(atoms.currentLocation)?._id],
        type: "active",
      });
    },
    mutationFn: async ({ source, dsp_location_id, location_id }) => {
      return await putRequest(`/dsp/${source}/rebind`, {
        arg: {
          location_id,
          dsp_location_id,
        },
      });
    },
  })),
  bindDSPLocation: wrapper<
    components["schemas"]["BindDspLocationDto"] & { source: DSP }
  >((get) => ({
    mutationKey: ["bindDSPLocation"],
    onSuccess: ({ source }) => {
      queryClient.resetQueries({
        queryKey: ["connectedDspLocations"],
        exact: false,
      });
      queryClient.refetchQueries({
        queryKey: ["integrations"],
        type: "active",
      });
      queryClient.refetchQueries({
        queryKey: ["locationPublishStatus", get(atoms.currentLocation)?._id],
        type: "active",
      });
      queryClient.refetchQueries({
        queryKey: ["locationPublishStatus"],
        type: "active",
      });
      queryClient.refetchQueries({
        queryKey: ["dspLocations", source],
        type: "active",
      });
      analytics.track(events.DSP_BOUND, { source });
    },
    onError: (err) => toast.error(err?.message || "Failed to bind DSP"),
    mutationFn: async ({ source, locations, _id }) => {
      await putRequest(`/dsp/${source}/bind`, {
        arg: {
          _id,
          locations,
          source,
        },
      });
      return { source };
    },
  })),
  catalogSync: wrapper<
    components["schemas"]["MerchantLocation"],
    components["schemas"]["PutCatalogResponseDto"]
  >((get) => ({
    mutationKey: ["catalogSync", get(atoms.currentLocation)],
    onSuccess: () => {
      toast.success("Resync success");
      invalidateCatalog();
    },
    onError: (error) => toast.error(error.message || "Failed to resync"),
    mutationFn: (location) => resyncMutation(location, get),
  })),
  updateMenu: wrapper<
    {
      update_data: components["schemas"]["UpdateMenuDto"]["update_data"];
      flag?: components["schemas"]["UpdateMenuDto"]["flag"];
    },
    components["schemas"]["UpdateMenuResponseDto"]
  >((get) => ({
    mutationKey: ["updateMenu"],
    onMutate: (newData) => {
      let needsForcePublish;
      const head = queryClient.getQueryData([
        "catalog",
        "head",
        get(atoms.currentLocation)?._id,
      ]) as any;

      queryClient.invalidateQueries({
        queryKey: ["menu", get(atoms.currentMenu)?._id],
        type: "active",
      });

      const oldMenu = queryClient.getQueryData([
        "menu",
        get(atoms.currentMenu)?._id,
        get(atoms.currentLocation)?._id,
        head,
      ]) as any;

      if (oldMenu?.menu) {
        const { construct: updatedMenu, needs_force_refresh } = updateConstruct(
          oldMenu.menu,
          newData,
        );
        queryClient.setQueriesData(
          {
            queryKey: [
              "menu",
              get(atoms.currentMenu)?._id,
              get(atoms.currentLocation)?._id,
              head,
            ],
          },
          {
            ...oldMenu,
            menu: updatedMenu,
          },
        );
        if (needs_force_refresh) needsForcePublish = needs_force_refresh;
      }

      return {
        head,
        oldMenu: oldMenu,
        needsForcePublish,
      };
    },
    onSuccess: (_, _a, context) => {
      toast.success("Success");

      invalidateCatalog(undefined, context.needsForcePublish);
    },
    // @ts-ignore
    onError: (error, _, context) => {
      if (context?.oldMenu) {
        queryClient.setQueriesData(
          {
            queryKey: [
              "menu",
              get(atoms.currentMenu)?._id,
              get(atoms.currentLocation)?._id,
              context?.head,
            ],
          },
          context?.oldMenu,
        );
      }
      toast.error(error.message || "Failed to update menu");
    },
    // @ts-ignore
    mutationFn: async (data) => {
      const currentLocation = get(atoms.currentLocation);
      const locationSpecificUpdate = currentLocation?.source === "square";

      const flag = data?.flag
        ? data?.flag
        : locationSpecificUpdate
          ? "specific_locations"
          : "all_locations";

      await postRequest("/menu/update_menu", {
        arg: {
          menu_id: get(atoms.currentMenu)?._id,
          update_data: data?.update_data,
          flag,
          ...(flag === "specific_locations" && {
            location_ids: [currentLocation._id],
          }),
        } as components["schemas"]["UpdateMenuDto"],
      });

      return data;
    },
  })),
  merchantExpress: wrapper<components["schemas"]["MerchantExpressDto"], IFixMe>(
    () => ({
      mutationKey: ["merchantExpress"],
      onError: () => toast.error("Failed to get stripe url"),
      mutationFn: async (data) =>
        postRequest("/merchant/connect", {
          arg: data,
        }),
    }),
  ),
  updateLocation: wrapper<
    components["schemas"]["UpdateMerchantLocationDto"],
    components["schemas"]["MerchantLocation"][]
  >(() => ({
    mutationKey: ["updateLocation"],
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ["locations"],
        type: "active",
      });
      queryClient.refetchQueries({
        queryKey: ["merchant", "locations"],
        type: "active",
      });
    },
    onError: () => toast.error("Failed to update location"),
    mutationFn: async (data: any) =>
      postRequest("/location/update", {
        arg: data,
      }),
  })),
  cancelSubscription: wrapper<
    components["schemas"]["SubscriptionPortalDto"],
    components["schemas"]["SubscriptionPortalResponseDto"]
  >(() => ({
    mutationKey: ["cancelSubscription"],
    mutationFn: async (variables: { subscription: any; return_url: string }) =>
      postRequest("/subscription/stripe/cancel/portal", {
        arg: {
          org_id: cookies.get(cookies.options.org_id),
          sub_id: variables.subscription._id,
          return_url: variables.return_url,
        },
      }),
  })),
  manageSubscription: wrapper<
    components["schemas"]["SubscriptionPortalDto"],
    components["schemas"]["SubscriptionPortalResponseDto"]
  >(() => ({
    mutationKey: ["manageSubscription"],
    mutationFn: async (variables: { subscription: any; return_url: string }) =>
      postRequest("/subscription/stripe/billing/portal", {
        arg: {
          org_id: cookies.get(cookies.options.org_id),
          sub_id: variables.subscription._id,
          return_url: variables.return_url,
        },
      }),
  })),
  createSubscription: wrapper<
    string,
    components["schemas"]["GetSubscriptionSessionResponseDto"]
  >(() => ({
    mutationKey: ["createSubscription"],
    onSuccess: (response) => {
      window.open(response.subscription_session.url, "_self");
      queryClient.refetchQueries({
        queryKey: ["querySubscription"],
        type: "active",
      });
    },
    mutationFn: async (type) => {
      const url = `${window.location.protocol}//${window.location.host}`;

      const body: components["schemas"]["GetSubscriptionSessionDto"] = {
        subscription_metadata: {
          cancel_url: `${url}/menu?subscription_status=failed`,
          org_id: cookies.get(cookies.options.org_id) as string,
          success_url: `${url}/menu?subscription_status=active`,
          type,
        },
      };

      return postRequest("/subscription/stripe/checkout", {
        arg: body,
      });
    },
  })),
  bulkPriceUpdate: wrapper<
    Omit<components["schemas"]["BulkPriceUpdateDto"], "catalog_id">
  >((get) => ({
    mutationKey: ["bulkPriceUpdate"],
    onError: (error) =>
      toast.error(error.message || "Failed to bulk update pricing"),
    onSuccess: (_, variables) => {
      if (!variables.read_only) {
        invalidateCatalog();
      }
    },
    mutationFn: (data) => {
      return postRequest("/catalog/bulk-price-update", {
        arg: {
          catalog_id: get(atoms.currentCatalog)?._id,
          ...data,
        },
      });
    },
  })),
  addMember: wrapper<components["schemas"]["AddOrgMemberDto"]>(() => ({
    mutationKey: ["addMember"],
    onSuccess: () => {
      toast.success(`Member added`);
      queryClient.refetchQueries({
        queryKey: ["members"],
        type: "active",
      });
    },
    mutationFn: async (data) => {
      return postRequest("/org/members", {
        arg: {
          ...data,
          org_id: cookies.get(cookies.options.org_id),
        },
      });
    },
  })),
  editMember: wrapper<components["schemas"]["UpdateOrgMemberDto"]>(() => ({
    mutationKey: ["editMember"],
    onSuccess: () => {
      toast.success(`Member updated`);
      queryClient.refetchQueries({
        queryKey: ["members"],
        type: "active",
      });
    },
    mutationFn: (data) => {
      return putRequest("/org/members", {
        arg: data,
      });
    },
  })),
  removeMember: wrapper<
    components["schemas"]["OrgMemberWithUser"]["uid"],
    { email: string }
  >((uid) => ({
    mutationKey: ["removeMember"],
    onSuccess: (data) => {
      toast.success(`User was removed`);
      queryClient.refetchQueries({
        queryKey: ["members"],
        type: "active",
      });
    },
    mutationFn: async (uid) =>
      deleteRequest("/org/members", {
        arg: {
          org_id: cookies.get(cookies.options.org_id),
          uid,
        },
      }),
  })),
  resetMemberPassword: wrapper<
    components["schemas"]["OrgMemberWithUser"]["uid"],
    { email: string }
  >((uid) => ({
    mutationKey: ["resetMember"],
    onSuccess: (data) => {
      toast.success(`User account was reset`);
      queryClient.refetchQueries({
        queryKey: ["members"],
        type: "active",
      });
    },
    mutationFn: async (uid) =>
      postRequest("/org/reset-member-password", {
        arg: {
          org_id: cookies.get(cookies.options.org_id),
          target_uids: [uid],
        },
      }),
  })),
  createMenuSchedule: wrapper<
    components["schemas"]["CreateSchedulesForLocationDto"],
    components["schemas"]["CreateSchedulesForLocationResponseDto"]
  >((get) => ({
    mutationKey: ["createMenuSchedule"],
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["menuSchedules", get(atoms.currentLocation)?._id],
        type: "active",
      });
      toast.success(
        "Schedule updated. Publish your menu to reflect your changes.",
      );
    },
    onError: (error: Error) => {
      toast.error(error?.message || "Failed to save new schedule");
    },
    mutationFn: async (data: {
      _id: string;
      menuSchedules: SchedulesByMenuId;
    }) =>
      postRequest("/menu/schedule/create", {
        arg: data,
      }),
  })),
  validateMenuSchedule: wrapper<
    components["schemas"]["CreateSchedulesForLocationDto"],
    components["schemas"]["ValidateSchedulesForLocationResponseDto"]
  >(() => ({
    mutationKey: ["validateMenuSchedule"],
    mutationFn: async (data: {
      _id: string;
      menuSchedules: SchedulesByMenuId;
    }) =>
      postRequest("/menu/schedule/validate", {
        arg: data,
      }),
  })),
  setDSPStatus: wrapper<
    {
      route: "resume" | "pause";
      dsp_location_id: string;
      timezone: string;
      paused_until?: "indefinite" | number;
    },
    components["schemas"]["ResumeLocationDto"] &
      components["schemas"]["PauseLocationDto"]
  >(() => ({
    mutationKey: ["setDSPStatus"],
    onSuccess: () => {
      toast.success(`Success`);
      queryClient.refetchQueries({
        queryKey: ["dspLocations"],
        type: "active",
      });
      queryClient.refetchQueries({
        queryKey: ["connectedDspLocations"],
        type: "active",
      });
    },
    mutationFn: async (options: {
      route: "resume" | "pause";
      dsp_location_id: string;
      paused_until: "indefinite" | number;
      timezone: string;
    }) => {
      const { route, dsp_location_id, paused_until, timezone } = options;
      return await postRequest(`/dsp/${route}`, {
        arg: {
          dsp_location_id,
          ...(paused_until && {
            paused_from: new Date().getTime(),
          }),
          ...(paused_until && {
            paused_until:
              paused_until === "indefinite"
                ? paused_until
                : new Date(paused_until).getTime(),
          }),
          timezone,
        },
      });
    },
  })),
  forgotPassword: wrapper<components["schemas"]["ForgotPasswordDto"]>(() => ({
    onSuccess: () => toast.success("Check your email to reset your password"),
    mutationFn: async ({ email_address }: { email_address: string }) =>
      postRequest("/auth/forgot-password", {
        arg: {
          email_address,
        },
      }),
    mutationKey: ["forgotPassword"],
  })),
  resetPassword: wrapper<components["schemas"]["ResetPasswordAuthDto"]>(() => ({
    onSuccess: () => toast.success("Successfully reset password"),
    mutationKey: ["resetPassword"],
    mutationFn: async ({
      email_address,
      password,
      reset_password_token,
    }: {
      email_address: string;
      password: string;
      reset_password_token: string;
    }) =>
      postRequest("/auth/reset-password", {
        arg: {
          email_address,
          password,
          reset_password_token,
        },
      }),
  })),
  updateTutorialStep: wrapper<
    components["schemas"]["MMTutorialStepDto"],
    components["schemas"]["MMTutorialStepResponseDto"]
  >(() => ({
    mutationKey: ["tutorialStep"],
    mutationFn: async (body) =>
      postRequest(`/menu-match/tutorial-step`, {
        arg: body,
      }),
  })),
  updateMatchedMenu: wrapper<
    components["schemas"]["UpdateMenuMatchResultStatusDto"]["result_update_set"],
    components["schemas"]["UpdateMenuMatchResultStatusResponseDto"]
  >((get) => ({
    mutationKey: ["updateMatchedMenu"],
    onSuccess: (res) => {
      queryClient.refetchQueries({
        queryKey: ["locations", get(atoms.mid)],
        type: "active",
      });
      queryClient.refetchQueries({
        queryKey: ["menuMatchRemaining", get(atoms.currentLocation)?._id],
        type: "active",
      });
      queryClient.refetchQueries({
        queryKey: ["menuMatch", get(atoms.currentLocation)?._id],
        type: "active",
      });
      queryClient.refetchQueries({
        queryKey: ["menus", get(atoms.currentLocation)?._id],
        type: "active",
      });

      if (res.mm_status === MENU_MATCH_STATUS.complete) {
        toast.success("Complete!");
        return res.mm_status;
      }
    },
    mutationFn: async (result_update_set) =>
      putRequest(`/menu-match/update`, {
        arg: {
          result_update_set,
          source: "uber",
          location_id: get(atoms.currentLocation)?._id,
        },
      }),
  })),
  cancelMM: wrapper<
    components["schemas"]["CancelMenuMatchDto"],
    components["schemas"]["CancelMenuMatchResponseDto"]
  >((get) => ({
    mutationKey: ["updateMatchedMenu"],
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ["locations"],
        exact: true,
        type: "active",
      });
      // TODO: implement new way to show complete status for mm
      // if (res.mm_status === MENU_MATCH_STATUS.complete) {
      //   toast.success("Complete!");
      //   return res.mm_status;
      // }
    },
    mutationFn: async (params) =>
      postRequest(`/menu-match/cancel`, {
        arg: params,
      }),
  })),
  optOutAllMM: wrapper<
    components["schemas"]["CancelMenuMatchDto"],
    components["schemas"]["CancelMenuMatchResponseDto"]
  >((get) => ({
    mutationKey: ["optOutAllMM"],
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ["locations"],
        exact: true,
        type: "active",
      });
      // TODO: implement new way to show complete status for mm
      // if (res.mm_status === MENU_MATCH_STATUS.complete) {
      //   toast.success("Complete!");
      //   return res.mm_status;
      // }
    },
    onMutate: async () => {
      const location_id = get(atoms.currentLocation)?._id;
      const locations = await queryClient.getQueryData<{
        locations: any[];
      }>(["locations", get(atoms.mid)]);

      const cached_location = (locations?.locations || []).find(
        (location) => location._id === location_id,
      );
      if (cached_location) {
        cached_location.mm_status = MENU_MATCH_STATUS.opt_out;
        await queryClient.setQueryData(["locations", get(atoms.mid)], {
          locations: locations?.locations?.map((location) =>
            location._id === cached_location?._id ? cached_location : location,
          ),
        });
      }
    },
    mutationFn: async (data) =>
      postRequest(`/menu-match/cancel`, {
        arg: data,
      }),
  })),
  // TODO: Backend is inconsistent with types
  updateMerchant: wrapper<components["schemas"]["UpdateMerchantDto"]>(() => ({
    mutationKey: ["updateMerchant"],
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ["orgs"],
        type: "active",
      });
      toast.success("Success");
    },
    mutationFn: async (params) =>
      postRequest(`/merchant/update`, {
        arg: params,
      }),
  })),
  createMerchant: wrapper<
    components["schemas"]["AddMerchantDto"],
    components["schemas"]["AddMerchantResponseDto"]
  >((get) => ({
    mutationKey: ["createMerchant", get(atoms.orgId)],
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ["orgs"],
        type: "active",
      });
      toast.success("Success");
    },
    mutationFn: async (params) =>
      postRequest(`/merchant`, {
        arg: {
          merchant_name: params.merchant_name,
          ...(params.image_url && { image_url: params.image_url }),
        },
        headers: {
          org_id: get(atoms.orgId),
        },
      }),
  })),
  deleteLocation: wrapper<
    string,
    components["schemas"]["DeleteLocationResponseDto"] & { location_id: string }
  >((get) => ({
    mutationKey: ["deleteLocation"],
    onSuccess: ({ location_id }) => {
      if (location_id === get(atoms.currentLocation)?._id) {
        queryClient.setQueryData(["currentLocation"], null);
      }
      queryClient.refetchQueries({
        queryKey: ["locations"],
        type: "active",
      });
      queryClient.refetchQueries({
        queryKey: ["location/merchant"],
        type: "active",
      });
      toast.success("Your request to delete your location has been submitted.");
    },
    mutationFn: async (location_id) => {
      const response = await deleteRequest(`/location/${location_id}`, {
        arg: {},
      });
      return {
        ...response,
        location_id,
      };
    },
  })),
  deleteIntegration: wrapper(() => ({
    mutationKey: ["deleteIntegration"],
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ["integrations"],
        type: "active",
      });
      queryClient.refetchQueries({
        queryKey: ["connectedDspLocations"],
        type: "active",
      });
      queryClient.refetchQueries({
        queryKey: ["connectedDspLocations"],
        type: "active",
      });
    },
    mutationFn: async (integration_id: string) =>
      deleteRequest(`/integrations?integration_id=${integration_id}`, {
        arg: {},
      }),
  })),

  cancelOrder: wrapper<components["schemas"]["CancelOrderDto"]>(() => ({
    mutationKey: ["cancelOrder"],
    mutationFn: async (order_id) => {
      postRequest(`/orders/cancel`, {
        arg: {
          _id: order_id,
        },
      });
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ["activeOrders"],
        type: "active",
      });
      queryClient.refetchQueries({
        queryKey: ["completeOrders"],
        type: "active",
      });
      toast.success("Order canceled");
    },
    onError: () => toast.error("Failed to cancel order"),
  })),
  updateOrder: wrapper<components["schemas"]["UpdateOrderStatusDto"]>(() => ({
    mutationKey: ["updateOrderStatus"],
    mutationFn: async (params) => {
      await postRequest(`/orders/update_status`, {
        arg: {
          _id: params._id,
          status: params.status,
        },
      });
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ["activeOrders"],
        type: "active",
      });
      queryClient.refetchQueries({
        queryKey: ["completeOrders"],
        type: "active",
      });
      toast.success("Order status updated");
    },
    onError: () => toast.error("Failed to update order status"),
  })),
  deleteDSPLocation: wrapper<
    string,
    components["schemas"]["DeleteDspLocationResponseDto"]
  >(() => ({
    mutationKey: ["deleteDSPLocation"],
    onSuccess: () => {
      queryClient.resetQueries({
        queryKey: ["connectedDspLocations"],
        exact: false,
      });
      queryClient.refetchQueries({
        queryKey: ["dspLocations"],
        type: "active",
      });
      toast.success("Success");
    },
    mutationFn: async (id) =>
      deleteRequest(`/dsp-location/${id}`, {
        arg: {},
      }),
  })),
  createMenu: wrapper<
    components["schemas"]["CreateNewMenuDto"] & { catalog_id: string },
    components["schemas"]["CreateNewMenuResponseDto"]
  >((get) => ({
    mutationKey: ["createMenu"],
    mutationFn: async ({ name, location_ids, catalog_id }) => {
      return postRequest("/menu", {
        arg: {
          name,
          location_ids,
        },
        headers: {
          catalog_id,
        },
      });
    },
    onSuccess: (data, variables) => {
      const head = queryClient.getQueryData([
        "catalog",
        "head",
        get(atoms.currentLocation)?._id,
      ]) as any;

      for (const id of variables?.location_ids) {
        queryClient.setQueryData(["menus", id, head], (old: any) => {
          if (!old) return { menus: [data?.menu] };
          const menus = [...old?.menus, data?.menu];
          return {
            menus,
          };
        });
      }

      toast.success("Menu created successfully");
    },
  })),
  editMenu: wrapper<
    components["schemas"]["UpdateMenuDto"],
    components["schemas"]["UpdateMenuResponseDto"]
  >(() => ({
    mutationKey: ["editMenu"],
    mutationFn: async (arg: components["schemas"]["UpdateMenuDto"]) => {
      return postRequest("/menu/update_menu", {
        arg,
      });
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ["menus"],
      });
    },
  })),
  deleteMenu: wrapper<
    components["schemas"]["DeleteMenuDto"],
    components["schemas"]["DeleteMenuResponseDto"]
  >((get) => ({
    mutationKey: ["deleteMenu"],
    mutationFn: async (arg: DeleteMenuDto) => {
      return postRequest("/menu/delete", {
        arg,
      });
    },
    onSuccess: (_, variables) => {
      const head = queryClient.getQueryData([
        "catalog",
        "head",
        get(atoms.currentLocation)?._id,
      ]) as any;

      for (const id of variables?.location_ids) {
        queryClient.setQueryData(["menus", id, head], (old: any) => {
          const menus = [...(old?.menus || [])]?.filter(
            (menu) => menu?._id !== variables?.menu_id,
          );
          return {
            menus,
          };
        });
      }
    },
  })),
  updatePrepTime: wrapper<components["schemas"]["UpdatePrepTimeDto"], unknown>(
    () => ({
      mutationKey: ["updatePrepTime"],
      mutationFn: async (params) => {
        return await putRequest("/location/prep-time", {
          arg: params,
        });
      },
      onSuccess: () => {
        queryClient.refetchQueries({
          queryKey: ["locations"],
          type: "active",
        });
        toast.success("Prep time updated");
      },
    }),
  ),
  integrationRefresh: wrapper<
    components["schemas"]["PosOAuthDto"] & {
      source: string;
      isDsp: boolean;
    }
  >((get) => ({
    mutationKey: ["integrationRefresh", get(atoms.mid)],
    mutationFn: async (params) => {
      let url = `/${!!params.isDsp ? "dsp" : "pos"}/${
        params.source
      }/refresh?code=${params?.code}&merchant_id=${get(
        atoms.mid,
      )}&integration_id=${params?.integration_id}`;

      if (params.provider_merchant_id) {
        url += `&provider_merchant_id=${params.provider_merchant_id}`;
      }

      await fetchRequest(url);
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ["locations"],
        exact: true,
        type: "active",
      });
      toast.success("Integration refreshed");
    },
  })),
  deleteMerchant: wrapper<any, any>((get) => ({
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ["orgs"],
        type: "active",
      });
      queryClient.refetchQueries({
        queryKey: ["locations"],
        type: "active",
      });
    },
    mutationKey: ["deleteMerchant", get(atoms.mid)],
    mutationFn: async ({ mid }: any) => {
      return deleteRequest(`/merchant?id=${mid}`, {
        arg: {
          mid,
        },
      });
    },
  })),
  confirmOauth: wrapper<
    components["schemas"]["PosOAuthDto"] & {
      source: string;
      method?: "GET" | "POST";
    },
    components["schemas"]["PosOAuthIntegrationResponseDto"]
  >((get) => ({
    onError: (data) => {
      toast.error(
        data.message ||
          "Failed to exchange OAuth code. Please try again later.",
      );
    },
    mutationKey: ["confirmOauth", get(atoms.mid)],
    mutationFn: async ({
      source,
      provider_merchant_id,
      code,
      metadata,
      method = "GET",
    }) => {
      const merchant_id = get(atoms.mid);
      if (method === "GET") {
        let url = `/pos/${source}/token?code=${code}`;
        if (merchant_id) {
          // confirmOauth can be called on oauth signin
          url += `&merchant_id=${merchant_id}`;
        }
        if (provider_merchant_id) {
          url += `&provider_merchant_id=${provider_merchant_id}`;
        }
        return fetchRequest(url);
      } else {
        return postRequest(`/pos/${source}/token`, {
          arg: {
            provider_merchant_id,
            source,
            code,
            merchant_id,
            metadata,
          },
        });
      }
    },
  })),
  confirmSSO: wrapper<
    operations["exchangeSSOToken"]["requestBody"]["content"]["application/json"],
    components["schemas"]["ExchangeSSOSessionResponseDto"]
  >((get) => ({
    onError: () => {
      toast.error("Failed to exchange SSO code. Please try again later.");
    },
    mutationKey: ["confirmSSO", get(atoms.mid)],
    mutationFn: async ({ sso_login_token }) => {
      return postRequest("/auth/exchange-session", {
        arg: {
          sso_login_token,
        },
      });
    },
  })),
  resendVerifyEmail: wrapper<{}>((get) => ({
    onError: (data) => {
      toast.error(data.message || "Failed to resend verify email.");
    },
    onSuccess: () => {
      toast.success("Email verification resent!");
    },
    mutationKey: ["resendVerifyEmail", get(atoms.mid)],
    mutationFn: async () => {
      return postRequest("/auth/email/create-verification", {});
    },
  })),
  verifyEmail: wrapper<
    operations["verifyEmail"]["requestBody"]["content"]["application/json"],
    components["schemas"]["VerifyEmailDto"]
  >((get) => ({
    onError: (data) => {
      toast.error(
        data.message ||
          "Failed to verify email. Please re-request verification email.",
      );
    },
    onSuccess: () => {
      toast.success("Email was verified!");
    },
    mutationKey: ["verifyEmail", get(atoms.mid)],
    mutationFn: async (data) => {
      return postRequest("/auth/email/verify", {
        arg: data,
      });
    },
  })),
  dspAssignMenus: wrapper<components["schemas"]["MenusBindDto"]>(() => ({
    onError: (error) => {
      toast.error(error.message || "Failed to assign menus.");
    },
    mutationKey: ["dspLocationAssignMenus"],
    onSuccess() {
      queryClient.refetchQueries({
        queryKey: ["connectedDspLocations"],
        type: "active",
      });
    },
    mutationFn: async ({ menu_ids, dsp_location_id }: any) => {
      return postRequest("/dsp-location/assign-menus", {
        arg: {
          menu_ids,
          dsp_location_id,
        },
      });
    },
  })),
  createOrg: wrapper<components["schemas"]["CreateOrgDto"]>(() => ({
    onError: () => {
      toast.error("Failed to create org.");
    },
    mutationKey: ["createOrg"],
    onSuccess() {
      queryClient.refetchQueries({
        queryKey: ["orgs"],
        type: "active",
      });
    },
    mutationFn: async ({ name }: components["schemas"]["CreateOrgDto"]) => {
      return postRequest("/org", { arg: { name } });
    },
  })),
  markOrgDefault: wrapper<components["schemas"]["UpdateOrgDefaultDto"]>(() => ({
    onError: () => {
      toast.error("Failed to make org default.");
    },
    mutationKey: ["markOrgDefault"],
    onSuccess() {
      queryClient.refetchQueries({
        queryKey: ["orgs"],
        type: "active",
      });
    },
    mutationFn: async ({
      org_id,
    }: components["schemas"]["UpdateOrgDefaultDto"]) => {
      return postRequest("/org/update/default", { arg: { org_id } });
    },
  })),
  updateOrg: wrapper<components["schemas"]["UpdateOrgDto"]>(() => ({
    onError: () => {
      toast.error("Failed to create org.");
    },
    mutationKey: ["updateOrg"],
    onSuccess() {
      queryClient.refetchQueries({
        queryKey: ["orgs"],
        type: "active",
      });
      toast.success("Success");
    },
    mutationFn: async (payload: components["schemas"]["UpdateOrgDto"]) => {
      return postRequest("/org/update", { arg: payload });
    },
  })),
  dspRemoveMenus: wrapper<components["schemas"]["MenusBindDto"]>(() => ({
    onError: () => {
      toast.error("Failed to remove menu.");
    },
    onSuccess() {
      queryClient.refetchQueries({
        queryKey: ["connectedDspLocations"],
        type: "active",
      });
      toast.success("Success");
    },
    mutationKey: ["dspLocationRemoveMenus"],
    mutationFn: async ({ menu_ids, dsp_location_id }) => {
      return postRequest("/dsp-location/remove-menus", {
        arg: {
          menu_ids,
          dsp_location_id,
        },
      });
    },
  })),
};

// defined here because resync is a tricky request we do at different spots
export const resyncMutation = async (
  locationOrLocations:
    | { integration_id: string; provider_id: string }[]
    | components["schemas"]["MerchantLocation"],
  get,
) => {
  let integration_id, provider_locations;

  const resyncLocation = locationOrLocations || get(atoms.currentLocation);

  if (Array.isArray(resyncLocation)) {
    integration_id = resyncLocation?.[0]?.integration_id;
    provider_locations = resyncLocation.map((loc) => ({
      provider_id: loc.provider_id,
    }));
  } else {
    integration_id = resyncLocation?.integration_id;
    provider_locations = [{ provider_id: resyncLocation?.provider_id }];
  }

  return putRequest(`/catalog`, {
    arg: {
      _id: integration_id,
      locations: provider_locations,
    },
  }).then(({ status, ingest_locations }) => {
    if (status === "success") {
      const locationIdToPoll = ingest_locations.map((loc) => loc._id);
      return Promise.all(
        locationIdToPoll.map((location_id) =>
          pollRequest(`/catalog/status?location_id=${location_id}`, {
            shouldPollFn: (
              data: components["schemas"]["CatalogStatusResponseDto"],
            ) => {
              return ["in_progress", "initializing"].includes(
                data.ingest_status,
              );
            },
            shouldPollOnErrorFn: (error) => {
              if (error instanceof FetchError) {
                if (error.response) {
                  return error?.response?.status >= 500;
                }
              }
              return false;
            },
            delay: 1000,
          }).then((data) => ({
            location_id,
            ingest_status: data?.ingest_status,
            ingest_failure_reason: data?.ingest_failure_reason,
          })),
        ),
      ).then((results) => {
        const failedResult = results.find(
          (result) => result.ingest_status === "failed",
        );

        if (failedResult) {
          throw new Error(failedResult.ingest_failure_reason);
        }

        return { status, ingest_locations };
      });
    }
  });
};
